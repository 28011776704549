<template>
    <div class="custom-matchup-container" :style="customMatchupContainerStyle">
        <div class="input-button-row">
            <input v-model="gameMatchupCustom" placeholder="Custom search e.g., Bulls vs Heat"
                @keyup.enter="customMatchupAnalyze" />
            <button class="clickable-button" @click="customMatchupAnalyze">
                <span v-if="customLoading" class="loading-indicator" style="white-space: nowrap">
                    <i class="fas fa-spinner fa-spin"></i> THINKING... <br />Up to 30 seconds.
                </span>
                <span v-else>Analyze Game</span>
            </button>
        </div>
        <details v-if="customResponse" open>
            <summary style="cursor: pointer; transition: all 0.25s ease">
                <div>Custom Matchup Analysis</div>
            </summary>
            <div class="llm-response">{{ customResponse }}</div>
        </details>
    </div>
</template>

<script setup lang="ts">
import { ref, defineEmits, computed, watch } from 'vue';
import { useGtm } from "@gtm-support/vue-gtm";

// Initialize GTM
const gtm = useGtm();
const trackEvent = gtm.trackEvent.bind(gtm);

// Define reactive properties
const gameMatchupCustom = ref("");
const customResponse = ref(null);
const customLoading = ref(false);
import { useStore } from "../stores/store";

const store = useStore();

const isCustomMatchupVisible = computed(() => store.isCustomMatchupVisible);

const customMatchupContainerStyle = computed(() => ({
    display: isCustomMatchupVisible.value ? 'block' : 'none',
}));

const emit = defineEmits(['fetch-strategy', 'update-loading']);

async function customMatchupAnalyze() {
    if (gameMatchupCustom.value.trim() !== "") {
        console.log('Setting loading to true');
        customLoading.value = true;
        
        // Track when user initiates a custom matchup analysis
        trackEvent({
            event: "custom_matchup_search",
            event_category: "user_interaction",
            event_action: "search",
            // event_label: gameMatchupCustom.value,
            noninteraction: false
        });

        try {
            await emit('fetch-strategy', gameMatchupCustom.value);
        } catch (error) {
            console.error('Error during analysis:', error);

        }
    }
}

// Add a new watch to handle loading state updates from parent
watch(() => store.customAnalysisLoading, (newValue) => {
    customLoading.value = newValue;
});
</script>

<style scoped>
@import "@/assets/games.css";

.input-button-row input {
    cursor: text;
}
</style>